.chat-app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 10px;
}

.message-container {
  display: flex;
  flex-direction: column;
  width: 100%; 
  height: 100%;
  background: white;
  border-radius: 6px;
}

.messages {
  flex: 1;
  position: relative;
}

.messages-scroller {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: scroll;
}

.message {
  align-self: flex-start;
  margin-top: 4px;
  padding: 8px 12px;
  max-width: 240px;
  background: #f1f0f0;
  border-radius: 16px;
  font-size: 12px;
}

.message-unread {
  align-self: flex-start;
  margin-top: 4px;
  padding: 8px 12px;
  max-width: 240px;
  background: #f1f0f0;
  /* background: red; */
  border-radius: 16px;
  font-size: 12px;
}

.message.me {
  align-self: flex-end;
  background: #f19e38;
  color: white;
}


.unread-line {
  color: darkgrey;
  background-color: black;
  border: 0;
  clear: both;
  height: 5px;
  width: 100%;
  margin-top: 0;
}

.unread-line-text {
  font-size: 8px;
  font-weight: bold;
  color: darkgrey;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0;
}

.chat-bar {
  height: 64px;
  font-size: 12px;
  border-top: 1px solid #ddd;
}

.chat-bar form {
  height: 100%;
  padding: 16px;
}

.chat-bar form input {
  width: 100%;
  height: 32px;
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 16px;
  outline: none;
}

.chat-bar form input:focus {
  border: 1px solid #f19e38;
}