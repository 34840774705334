#page-container {
  margin: 20px auto;
}

.job-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: repeat(auto-fit, 240px);
  grid-auto-flow: dense;
  grid-gap: 10px;
}

.job-grid-card {
  height: 100%;
  width: 100%;
}

.job-budget {
  position: absolute;
  bottom: 0;
}

.proposals-grid-card {
  margin: 20px auto;
}
.proposals-budget {
  position: absolute;
  bottom: 0;
}

.proposal-delete-alert {
  width: 50%;
  text-align: center;
  margin: auto;
}

.proposal-card-btns {
  margin: 10px auto;
  text-align: center;
}

.proposal-status {
  font-weight: bold;
}

.proposal-delete-alert {
  max-width: 300px;
  max-height: 300px;
}

.proposal-delete-alert-btns button {
  font-weight: bold;
  margin: 5px;
}
