.chat-hub-window {
	display: flex;
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 50%;
	height: 50%;
  box-shadow: 5px 5px 3px -1px rgba(0,0,0,0.21);
	background-color: #f2f2f2;
	border: 1px solid lightgrey;
	border-radius: 10px;
	padding: 10px;
}

.chat-hub-row {
	height: 100%;
}

.chat-list-col {
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.chat-list-container-wrapper {
	height: 90%;
	overflow-y: auto;
}

.chat-window {
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.chat-hub-empty-message {
	text-align: center;
}

.minimize-btn {
	display: flex;
	width: 27px;
	height: 27px;
	padding: 0;
	padding-left: 1px;
}

.minimize-icon {
	padding-bottom: 2px;
	padding-right: 1px;
}

.maximize-btn {
	position: fixed;
	bottom: 23px;
	right: 23px;
	width: 60px;
	height: 60px;
	border-radius: 50%;
  box-shadow: 5px 5px 3px -1px rgba(0,0,0,0.21); 
}

.chat-list-header {
	font-weight: bold;
	padding-left: 12px;
	padding-bottom: 5px;
	width: 100%;
	text-align: center;
	flex-wrap: nowrap;
}

.chat-list-header-title {
	text-align: left;
	padding-right: 10px;
}

.chat-list-container {
	width: 100%;
	height: 100%;
	overflow: scroll;
}

.chat-list-tabs {
	position: relative;
	flex:1;
	height: 100%;
	margin: 0;
	padding-left: 12px;
}

.chat-list-item {
	align-self: center;
	background-color: rgb(216, 214, 214);
	border: 1px solid darkgrey;
	border-radius: 3px;
	margin-top: 1px;
	overflow: hidden;
	height: 50px;
	width: 100%;
}

.chat-list-item-active {
	color: rgb(32, 32, 243);
 }

.chat-list-item-job-title {
	font-size: smaller;
}

.chat-list-unread-icon {
	float: right;
	color: #f19e38;
}

.mobile-chat-alert {
	display: inline-flex;	
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.mobile-chat-alert-body {
	text-align: left;
	align-self: center;
	padding-right: 5px;
}

.mobile-chat-alert-button {
	width: 27px;
	height: 27px;
	padding: 0;
	background-color: transparent;
	border: 0;
}

.close-alert-icon {
	padding: 0;
	padding-right: 1px;
	padding-bottom: 2px;
	color: grey;
}